<template>
    <div>
        <div class="counter-group col">
            <div class="counter counter-lg">
                <small v-if="prepend">{{ prepend }}</small>
                <counter :value="valueFormatter(amount)"></counter>
                <small v-if="append">{{ append }}</small>
                <small class="achieve-rate" v-if="amount_target">
                    (<counter :value="total_achieved"></counter>% meta)
                </small>
            </div>
            <p>Valor</p>
        </div>
        <div class="row">
            <div class="counter-group col-sm-6">
                <counter class="counter" :value="quantity"></counter>
                <p>Quantidade</p>
            </div>
            <div class="counter-group col-sm-6">
                <div class="counter">
                    <small v-if="prepend">{{ prepend }}</small>
                    <counter :value="valueFormatter(avgTicket)"></counter>
                    <small v-if="append">{{ append }}</small>
                </div>
                <p>Ticket médio</p>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .counter-group {
        text-align: center;
    }

    .counter {
        font-size: 25px;
        font-weight: 500;
        color: #666;
        text-align: center;
    }

    .counter-lg {
        font-size: 35px;
    }

    .achieve-rate {
        font-size: 1.5rem;
        padding-left: 1rem;
    }

    @media (max-width: 840px) {
        .achieve-rate {
            display: block;
        }
    }

    @media (min-width: 1000px) {
        .counter {
            font-size: 28px;
        }

        .counter-lg {
            font-size: 45px;
        }
    }

    @media (min-width: 1700px) {
        .counter {
            font-size: 35px;
        }

        .counter-lg {
            font-size: 55px;
        }
    }
</style>

<script>
import Counter from "./shared/Counter";
import DataHelper from "../../helpers/DataHelper";

export default {
    components: {
        Counter
    },
    props: {
        amount_target: {
            type: Number,
            default: 0
        },
        quantity: {
            type: Number,
            required: true
        },
        amount: {
            type: Number,
            required: true
        },
        duration: {
            type: Number,
            default: 4
        },
        valueFormatter: {
            type: Function,
            default: (v) => {
                return v;
            }
        },
        append: {
            type: String,
            default: ''
        },
        prepend: {
            type: String,
            default: ''
        }
    },
    computed: {
        avgTicket() {
            return DataHelper.roundNumber(this.amount / this.quantity, 0);
        },
        total_achieved() {
            if (!this.amount_target) return 0;
            return Math.floor(this.amount / this.amount_target * 100);
        }
    }
}
</script>

