<template>
    <main class="app-theme">

        <navbar-app/>

        <template v-if="dateCampaign.isReady">

            <campaign-ended-app v-if="dateCampaign.ended"/>

            <template v-else>
                <notifications classes="notify-new-donation"
                               group="new-donation"
                               position="top left"
                               :width="notificationWidth"/>

                <div class="row">
                    <div class="column">
                        <banner-rotator/>
                    </div>
                    <div class="column">
                        <div class="cards-container">
                            <div class="card">
                                <div class="card-body center mobile-no-max-height">
                                    <h5 class="card-title">Total Arrecadado</h5>
                                    <three-counters v-if="campaign.aggregate && campaign.aggregate.count_items && campaign.aggregate.sum_amount"
                                                    :quantity="campaign.aggregate.count_items"
                                                    prepend="R$ "
                                                    :amount="campaign.aggregate.sum_amount"/>
                                    <p v-else-if="!campaign.init" class="text-center text-muted">
                                        <font-awesome-icon icon="circle-notch" spin size="3x"/>
                                    </p>
                                    <p v-else class="text-center"><em>Não há doações</em></p>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-body center">
                                    <h5 class="card-title">Últimas Doações</h5>
                                    <latest-donations v-if="campaign.aggregate && campaign.aggregate.latest"
                                                      :donations="campaign.aggregate.latest"
                                                      :metadata="'unidades'"
                                                      :value-formatter="formatCurrency"
                                                      :limit="5"/>
                                    <p v-else-if="!campaign.init" class="text-center text-muted">
                                        <font-awesome-icon icon="circle-notch" spin size="3x"/>
                                    </p>
                                    <p v-else class="text-center"><em>Não há doações</em></p>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-body center">
                                    <h5 class="card-title">Top Doadores (R$)</h5>
                                    <bar-donation-amount-by-donor v-if="campaign.aggregate && campaign.aggregate.top_donors_amount"
                                                                  :donors="campaign.aggregate.top_donors_amount"
                                                                  :top="10"/>
                                    <p v-else-if="!campaign.init" class="text-center text-muted">
                                        <font-awesome-icon icon="circle-notch" spin size="3x"/>
                                    </p>
                                    <p v-else class="text-center"><em>Não há doações</em></p>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-body center">
                                    <h5 class="card-title">Doações Diárias (R$)</h5>
                                    <bar-donation-amount-by-date v-if="campaign.aggregate && campaign.aggregate.daily_amount"
                                                                 :amounts="campaign.aggregate.daily_amount"
                                                                 :scale-formatter="scaledFormatter"
                                                                 :limit="7"/>
                                    <p v-else-if="!campaign.init" class="text-center text-muted">
                                        <font-awesome-icon icon="circle-notch" spin size="3x"/>
                                    </p>
                                    <p v-else class="text-center"><em>Não há doações</em></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </template>
    </main>
</template>

<script>
import CampaignMixin from "../../mixins/CampaignMixin";
import NavbarApp from "../../components/navbars/NavbarApp";

import BarDonationAmountByDate from "../../components/charts/BarDonationAmountByDate";
import LatestDonations from "../../components/lists/LatestDonations";

import BannerRotator from "../../components/banners/BannerRotator";
import ThreeCounters from "../../components/counters/ThreeCounters";
import BarDonationAmountByDonor from "../../components/charts/BarDonationAmountByDonor";
import Formatter from "../../helpers/Formatter";
import CampaignEndedApp from "../../components/warnings/EndedCampaignApp";

export default {
    mixins: [CampaignMixin],
    components: {
        BarDonationAmountByDonor,
        CampaignEndedApp,
        ThreeCounters,
        BannerRotator,
        LatestDonations,
        BarDonationAmountByDate,
        NavbarApp
    },
    methods: {
        _hook_onNewDonationCreated(donation) {
            this.$notify({
                duration: 2000,
                group: 'new-donation',
                title: 'Doação Recebida',
                text: `${donation.donor.name} doou ${donation.amount}`
            });
        },
        formatCurrency(value) {
            return Formatter.formatCurrency(value, true, 0);
        },
        scaledFormatter(value) {
            return Formatter.formatDecimal(value, 0);
        }
    },
    computed: {
        notificationWidth() {
            return (window.innerWidth < 400 ? 300 : 400) + 'px';
        }
    }
}
</script>

