import Formatter from "./Formatter";

export default class ChartHelper {

    static formatScaledNumeric(value, prefix = true, precision = 0) {
        const scaled = parseFloat(value) / 1000;
        const suffix = 'k';
        const formatted = Formatter.formatCurrency(scaled, prefix, precision) + suffix;

        return value !== 0 ? formatted : 0;
    }
}
