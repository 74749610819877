<template>
    <bar-amount-by-donor :labels="labels"
                         :datasets="datasets"
                         :scale-y-formatter="scaledFormatter"
                         :value-formatter="scaledFormatter"/>
</template>

<script>
import {Vibrant} from '@/color_schemes/custom';
import BarAmountByDonor from './shared/BarAmountByDonor';
import ChartHelper from '@/helpers/ChartHelper';

export default {
    components: {
        BarAmountByDonor
    },
    props: {
        donors: {
            type: Array,
            required: true
        },
        top: {
            type: Number,
            default: 50
        }
    },
    methods: {
        scaledFormatter(value) {
            return ChartHelper.formatScaledNumeric(value, false, 1);
        }
    },
    computed: {
        sliced() {
            return this.donors.slice(0, this.top);
        },
        labels() {
            return this.sliced.map(x => x.name);
        },
        values() {
            return this.sliced.map(x => x.sum);
        },
        datasets() {
            return [
                {
                    label: 'Doações em Kg',
                    data: this.values,
                    backgroundColor: Vibrant
                }
            ];
        }
    }
}
</script>
